import classes from './SearchableTableHeader.module.css';

import { type BoxProps, Group, TableTh, Text, TextInput, UnstyledButton, VisuallyHidden } from '@mantine/core';
import { IconArrowDown, IconArrowUp, IconArrowsUpDown } from '@tabler/icons-react';
import cx from 'clsx';
import { type ReactNode, useState } from 'react';

type SearchableTableHeaderStylesNames = 'root' | 'control' | 'input-wrapper' | 'input' | 'label' | 'icon';

interface SearchableTableHeaderProps extends BoxProps {
  placeholder?: string;
  defaultValue?: string;
  reversed?: boolean;
  sorted?: boolean;
  eager?: boolean;
  onSort?(): void;
  onSearch(search: string): void;
  children?: string;
  action?: ReactNode;
  classNames?: Partial<Record<SearchableTableHeaderStylesNames, string>>;
  styles?: Partial<Record<SearchableTableHeaderStylesNames, React.CSSProperties>>;
}

export function SearchableTableHeader({
  placeholder,
  defaultValue,
  sorted = false,
  reversed = false,
  eager = false,
  className,
  classNames,
  style,
  styles,
  children,
  action,
  onSearch,
  onSort,
  ...others
}: SearchableTableHeaderProps) {
  const Icon = sorted ? reversed ? IconArrowUp : IconArrowDown : IconArrowsUpDown;

  const [search, setSearch] = useState(defaultValue ?? '');

  return (
    <TableTh className={cx(classNames?.root, className)} style={[styles?.root, style]} {...others}>
      <Group
        justify="space-between"
        wrap="nowrap"
        gap="xs"
        className={cx(classes.control, classNames?.control)}
        style={styles?.control}>

        <TextInput
          placeholder={placeholder}
          variant="unstyled"
          className={cx(classes['input-wrapper'], classNames?.['input-wrapper'])}
          classNames={{ input: cx(classes.input, classNames?.input) }}
          style={styles?.input}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            if (eager) {
              onSearch(e.target.value);
            }
          }}
          onKeyDown={(e) => {
            if (e.key.toLowerCase() === 'enter') {
              onSearch(search);
            }
          }} />

        <VisuallyHidden>
          <Text fw={500} lineClamp={1} size="sm" className={cx(classes.label, classNames?.label)} style={styles?.label}>
            {children}
          </Text>
        </VisuallyHidden>
        <Group gap={5} p="xs">
          {action}
          {onSort &&
          <UnstyledButton className={cx(classes.icon, classNames?.icon)} style={styles?.icon} onClick={onSort}>
              <Icon size="0.875rem" stroke={2} />
            </UnstyledButton>}

        </Group>
      </Group>
    </TableTh>);

}